<template>
  <div class="iconlist">
    <div class="contanier">
      <div class="titile">
        <h2>将您的关注者转化为客户</h2>
        <p>微型着陆页的无限潜力</p>
      </div>
      <div class="iconinfo">
        <div class="iconleft">
          <div class="itemicon">
            <div
              v-for="(item, index) in iconlist"
              :key="index"
              :class="{ item: true, active: item.id == typeid }"
              @click="change(item.id, item.one_image)"
            >
              <el-image
                style="width: 30px; height: 30px"
                :src="item.image"
                fit="contain"
              ></el-image>
              <p :class="{ text: item.id == typeid }">{{ item.name }}</p>
            </div>
          </div>
          <div class="icon-titile">
            <h2>一个生物链接，无限的可能性。Infortree中的多个链接。</h2>
            <p>
              Infortree提供了完整的信息呈现方式，通过一个链接包含一组其他链接和内容，包括联系表格，文本，视频，图像，音频等等。
            </p>
          </div>
        </div>
        <div class="iconrigth">
          <el-image
            style="width: 360px; height: 600px"
            :src="require('@/util/images/index/pic_phone.png')"
            fit="contain"
          ></el-image>
          <img :src="one_image" fit="contain" id="iphone" class="iphone" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "h5-iconlist",
  props: {
    iconlist: {
      type: Array
    }
  },
  components: {},

  directives: {},

  data() {
    return { typeid: "", one_image: "" };
  },

  mounted() {
    const that = this;
    this.$nextTick(function() {
      that.typeid = that.iconlist[0].id;
      that.one_image = that.iconlist[0].one_image;
    });
  },

  methods: {
    change(id, img) {
      this.typeid = id;
      this.one_image = img;
      var iphone = document.querySelector("#iphone");
      iphone.setAttribute("class", "iphone rotate-vert-left");
      setTimeout(function() {
        iphone.setAttribute("class", "iphone");
      }, 1000);
    }
  }
};
</script>

<style lang="less" scoped>
.rotate-vert-left {
  -webkit-animation: rotate-vert-left 1s cubic-bezier(0.645, 0.045, 0.355, 1)
    both;
  animation: rotate-vert-left 1s cubic-bezier(0.645, 0.045, 0.355, 1) both;
}

@-webkit-keyframes rotate-vert-left {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes rotate-vert-left {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.iconinfo {
  display: flex;
  .iconleft {
    padding-top: 30px;
    width: 720px;
    .itemicon {
      .item {
        width: 136px;
        height: 116px;
        border-radius: 7px;
        display: inline-block;
        background: #ffffff;
        border: 1px solid #cccccc;
        margin: 0 7px 7px 0;
        padding-top: 30px;
        text-align: center;
        p {
          font-size: 16px;
          color: #6d6d6d;
          line-height: 16px;
          margin-top: 10px;
        }
        .text {
          color: #ffffff;
        }
      }
      .item:hover {
        background: linear-gradient(135deg, #2ec2ff 0%, #0383e4 100%);
      }
      .active {
        background: linear-gradient(135deg, #2ec2ff 0%, #0383e4 100%);
      }
    }
    .icon-titile {
      padding: 30px 0 100px 0;
      h2 {
        font-size: 30px;
        color: #000000;
        line-height: 40px;
      }
      p {
        font-size: 14px;
        color: #000000;
        line-height: 26px;
      }
    }
  }
  .iconrigth {
    margin-left: 20px;
    position: relative;
    .iphone {
      position: absolute;
      top: 21px;
      left: 60px;
      width: 241px;
      height: 516px;
      border-radius: 30px;
    }
  }
}
.iconlist {
  // height: 715px;
  background: #f0f2f5;
}
.titile {
  padding-top: 100px;
}
.titile h2 {
  text-align: center;
  font-size: 30px;
  color: #000000;
  line-height: 40px;
  margin-bottom: 10px;
}
.titile p {
  text-align: center;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
  margin-bottom: 50px;
}
</style>
