<template>
  <div class="inforlist">
    <div
      class="infor"
    >
      <div class="contanier">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="img">
              <el-image
                style="width: 500px; height: 340px"
                :src="inforlist[0].image"
                fit="contain"
              ></el-image>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="text">
              <h2>{{ inforlist[0].name }}</h2>
              <div v-html="inforlist[0].content"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div
      class="infor1"
    >
      <div class="contanier">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="text">
              <h2>{{ inforlist[1].name }}</h2>
              <div v-html="inforlist[1].content"></div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="img">
              <el-image
                style="width: 500px; height: 340px"
                :src="inforlist[1].image"
                fit="contain"
              ></el-image>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
     <div
      class="infor"
    >
      <div class="contanier">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="img">
              <el-image
                style="width: 500px; height: 340px"
                :src="inforlist[2].image"
                fit="contain"
              ></el-image>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="text">
              <h2>{{ inforlist[2].name }}</h2>
              <div v-html="inforlist[2].content"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div
      class="infor1"
    >
      <div class="contanier">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="text">
              <h2>{{ inforlist[3].name }}</h2>
              <div v-html="inforlist[3].content"></div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="img">
              <el-image
                style="width: 500px; height: 340px"
                :src="inforlist[3].image"
                fit="contain"
              ></el-image>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
         <div
      class="infor"
    >
      <div class="contanier">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="img">
              <el-image
                style="width: 500px; height: 340px"
                :src="inforlist[4].image"
                fit="contain"
              ></el-image>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="text">
              <h2>{{ inforlist[4].name }}</h2>
              <div v-html="inforlist[4].content"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "inforlist",
  props: {
    inforlist: {
      type: Array
    }
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>

<style lang="less" scoped>
.img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
}
.infor {
  height: 700px;
  background: #f3f6f8;
}
.infor1 {
  height: 700px;
  background: #fff;
}
.text {
  height: 700px;
  padding-top: 120px;
  padding-right: 30px;
  h2 {
    font-size: 36px;
    color: #000000;
    line-height: 47px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 30px;
    color: #000000;
    line-height: 36px;
    margin-bottom: 40px;
  }
  p {
    font-size: 16px;
    color: #000000;
    line-height: 28px;
    margin-bottom: 20px;
    text-align: justify;
  }
}
</style>
