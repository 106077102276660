<template>
  <div class="index">
    <div class="banner">
      <div class="contanier">
        <el-row :gutter="20">
          <el-col :span="12">
            <div
              class="banneritem"
              v-for="(item, index) in indextreg"
              :key="index"
              v-if="index === 0"
            >
              <h2>{{ item.name }}</h2>
              <p>
                {{ item.content }}
              </p>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <div
                  class="banneritem"
                  v-for="(item, index) in indextreg"
                  :key="index"
                  v-if="index === 1"
                >
                  <h3>{{ item.name }}</h3>
                  <p>
                    {{ item.content }}
                  </p>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="banneritem"
                  v-for="(item, index) in indextreg"
                  :key="index"
                  v-if="index === 2"
                >
                  <h3>{{ item.name }}</h3>
                  <p>
                    {{ item.content }}
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <div
                  class="banneritem"
                  v-for="(item, index) in indextreg"
                  :key="index"
                  v-if="index === 3"
                >
                  <h3>{{ item.name }}</h3>
                  <p>
                    {{ item.content }}
                  </p>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="banneritem"
                  v-for="(item, index) in indextreg"
                  :key="index"
                  v-if="index === 4"
                >
                  <h3>{{ item.name }}</h3>
                  <p>
                    {{ item.content }}
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-button class="btn" @click="register" plain>免费注册</el-button>
          </el-col>
          <el-col :span="12">
            <div class="bannerimg">
              <el-image
                style="width: 500px; height: 377px"
                :src="indextreg[0].image"
                fit="contain"
              ></el-image>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <h5IconList :iconlist="iconlist"></h5IconList>
    <inforList :inforlist="inforlist"></inforList>
    <swiper :swiper="swiper"></swiper>
    <vipMoney :viptext="viptext"></vipMoney>
  </div>
</template>

<script>
import h5IconList from "./components/h5-iconlist.vue";
import inforList from "./components/inforlist.vue";
import swiper from "./components/swiper.vue";
import vipMoney from "./components/vip-money.vue";
import { index } from "../../api/userapi";
export default {
  // 组件名称
  name: "index",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: { h5IconList, inforList, swiper, vipMoney },
  // 组件状态值
  data() {
    return {
      indextreg: "",
      iconlist: "",
      inforlist: "",
      swiper: "",
      viptext: "",
      userid: ""
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 在实例初始化之后，组件属性计算之前，如data属性等
   */
  beforeCreate() {},
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    if (this.$route.query.userid !== undefined) {
      this.userid = window.atob(this.$route.query.userid);
    }
    window.localStorage.setItem("infortree-shareid", this.userid);
    this.getinfo();
  },
  mounted() {},
  methods: {
    register() {
      this.$router.push("/register");
    },
    getinfo() {
      index()
        .then(res => {
          this.indextreg = res.article;
          this.iconlist = res.article18;
          this.inforlist = res.article19;
          this.swiper = res.article24;
          this.viptext = res.goods_list;
        })
        .catch();
    }
  }
};
</script>
<style lang="less" scoped>
.banner {
  height: 530px;
  background: linear-gradient(270deg, #009fda 0%, #00257a 100%);
  h2 {
    font-size: 28px;
    color: #ffffff;
    line-height: 37px;
    margin: 67px 0 10px 0;
  }
  p {
    font-size: 14px;
    color: #ffffff;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .el-col {
    padding: 0 20px 0 0 !important;
  }
  .el-row {
    margin: 0 !important;
  }
  h3 {
    font-size: 22px;
    color: #ffffff;
    line-height: 29px;
  }
  .banneritem {
    height: 110px;
  }
  .btn {
    width: 190px;
    height: 50px;
    background: #ffffff;
    border-radius: 5px;
    font-size: 18px;
    color: #2d2f39;
    line-height: 24px;
  }
  .bannerimg {
    display: flex;
    justify-content: center;
    margin-top: 67px;
  }
}
</style>
