<template>
  <div class="swiper">
    <div class="contanier h">
      <div class="title">受到用户的喜爱</div>
      <el-carousel indicator-position="outside">
        <el-carousel-item
          v-for="(item, index) in swiper"
          :key="index"
          v-if="item.length > 0"
        >
          <div class="swiperitem">
            <div class="card">
              <p>{{ item[0].content }}</p>
              <div class="name">{{ item[0].name }}</div>
            </div>
            <div class="card">
              <p>{{ item[1].content }}</p>
              <div class="name">{{ item[1].name }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "swiper",
  props: {
    swiper: {
      type: Array
    }
  },
  components: {},

  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>

<style lang="less" scoped>
.swiperitem {
  display: flex;
  justify-content: space-between;
}
.card {
  width: 540px;
  height: 253px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(8, 21, 66, 0.06);
  padding: 30px;
  p {
    font-size: 20px !important;
    color: #000000 !important;
    line-height: 34px !important;
    margin-bottom: 10px !important;
  }
  .name {
    text-align: right;
    font-size: 16px;
    color: #0383e4;
    // margin-top: 20px;
    line-height: 21px;
  }
}
.swiper {
  height: 700px;
  background: #ffffff;
}
.h {
  height: 700px;
}
.title {
  text-align: center;
  font-size: 30px;
  color: #000000;
  padding: 120px 0 100px 0;
}
/deep/.el-carousel__button {
  display: block;
  opacity: 0.48;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #0383e4;
}
</style>
