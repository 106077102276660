import { render, staticRenderFns } from "./inforlist.vue?vue&type=template&id=f70723c2&scoped=true&"
import script from "./inforlist.vue?vue&type=script&lang=js&"
export * from "./inforlist.vue?vue&type=script&lang=js&"
import style0 from "./inforlist.vue?vue&type=style&index=0&id=f70723c2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f70723c2",
  null
  
)

export default component.exports